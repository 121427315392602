export const commonContents = {
  socialList: [
    {
      link: 'https://www.facebook.com/klianlopez/',
      icon: 'fab fa-facebook-f'
    },
    {
      link: 'https://twitter.com/kimlianlopez',
      icon: 'fab fa-twitter'
    },
    {
      link: 'https://www.linkedin.com/in/kimlianlopez/',
      icon: 'fab fa-linkedin-in'
    },
    {
      link: 'https://github.com/kimlianlopez',
      icon: 'fab fa-github-alt'
    },
    {
      link: 'https://stackoverflow.com/users/12691325/kimlianlopez',
      icon: 'fab fa-stack-overflow'
    }
  ],
  pages: [
    {
      iconName: 'fa-home',
      name: 'home',
      href: '/',
      withAction: false
    },
    {
      iconName: 'fa-user',
      href: '/me',
      name: 'me'
    },
    {
      iconName: 'fa-cubes',
      href: '/portfolio',
      name: 'portfolio'
    },
    {
      iconName: 'fa-envelope',
      href: '/contact',
      name: 'contact'
    }
  ]
}

export const homeContents = {
  menuButtons: [
    {
      iconName: 'fa-home',
      name: 'home',
      withAction: false
    },
    {
      iconName: 'fa-user',
      name: 'me'
    },
    {
      iconName: 'fa-cogs',
      name: 'portfolio'
    },
    {
      iconName: 'fa-envelope',
      name: 'contact'
    }
  ]
}

export const meContents = {
  skills: [
    'HTML5',
    'PHP',
    'CSS3',
    'SCSS',
    'JAVASCRIPT',
    'REACT',
    'GATSBYJS',
    'DUDA EXPERT',
    'ADOBE XD',
    'PSD TO HTML',
    'GIT'
  ]
}

export const portfolioContents = {
  projectList: [
    {
      name: 'JLopez Photografia',
      image: '/jlopez-photografia.JPG',
      tags: ['react', 'gatsbyjs', 'javascript', 'html5', 'css3', 'scss'],
      links: {
        site: 'https://jlopezphotografia.com/'
      }
    },
    {
      name: 'Gatsby Starter Readify',
      image: '/gatsby-starter-readify.png',
      tags: ['react', 'gatsbyjs', 'javascript', 'html5', 'css3', 'scss'],
      links: {
        site: 'https://gatsby-starter-readify.netlify.app/',
        github: 'https://github.com/kimlianlopez/gatsby-starter-readify'
      }
    },
    {
      name: 'Snake Game',
      image: '/snake-game.jpg',
      tags: ['react', 'javascript', 'scss'],
      links: {
        site: 'https://code.thekimlopez.com/snake-game/',
        github:
          'https://github.com/kimlianlopez/code-lab/tree/master/src/projects/Snake'
      }
    },
    {
      name: 'Gooey Social Icons',
      image: '/gooeysocialicons.jpg',
      tags: ['react', 'javascript', 'scss'],
      links: {
        site: 'https://code.thekimlopez.com/gooey-social-icons/',
        github:
          'https://github.com/kimlianlopez/code-lab/tree/master/src/projects/GooeySocialIcons'
      }
    }
  ]
}
