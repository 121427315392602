import React from 'react'

import Header from '../Header'
import Footer from '../Footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/globals.scss'

const Layout = ({ children, isNavVisible, noActive = false }) => {
  return (
    <div id="mainContainer">
      <Header isNavVisible={isNavVisible} noActive={noActive} />
      <main className="container flex-grow-1" style={{ overflow: 'hidden' }}>
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
