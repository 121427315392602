import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { SiteMetaData } from '../../api/graphql/siteMetadata'

const Head = ({ title, lang, description, meta }) => {
  const site = SiteMetaData()

  const siteTitle = title ? `${title} | ${site.author}` : `${site.author}`
  const metaDescription = description || site.description
  const metaImage = `${site.url}${site.image}`

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={siteTitle}
      link={[
        {
          href: site.url,
          rel: 'canonical'
        },
        {
          href: '/favicon.png',
          rel: 'shortcut icon',
          type: 'image/png'
        }
      ]}
      meta={[
        {
          charSet: 'utf-8'
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `image`,
          content: metaImage
        },
        {
          name: `url`,
          content: site.url
        },
        {
          property: `og:title`,
          content: siteTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:image`,
          content: metaImage
        },
        {
          property: `og:url`,
          content: site.url
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `twitter:card `,
          content: 'summary_large_image'
        },
        {
          property: `twitter:title`,
          content: siteTitle
        },
        {
          property: `twitter:description`,
          content: metaDescription
        },
        {
          property: `twitter:image`,
          content: metaImage
        }
      ].concat(meta)}
    />
  )
}

Head.defaultProps = {
  lang: `en`,
  title: '',
  description: '',
  meta: []
}

Head.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
}

export default Head
