import React from 'react'

import { SiteMetaData } from '../../api/graphql/siteMetadata'
import { commonContents } from '../../contents'
import './styles.scss'

const SocialLinks = ({ socialList }) => {
  return (
    <ul className="d-flex p-0 m-0 align-items-center">
      {socialList.map(({ icon, link }, i) => {
        return (
          <li key={i}>
            <a href={link} target="_blank" rel="noreferrer">
              <i className={icon}></i>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

const Footer = () => {
  const { author } = SiteMetaData()

  return (
    <footer className="container">
      <div className="row row-cols-2 flex-column-reverse flex-sm-row">
        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-md-start copyright">
          <i className="fas fa-copyright"></i>
          <span>twentytwentyone |</span>
          <span className="author-name">
            <strong>{author}</strong>
          </span>
        </div>
        <div className="col-12 col-sm-6 social-links mb-4 mb-md-0 d-flex justify-content-md-end justify-content-center">
          <SocialLinks socialList={commonContents.socialList} />
        </div>
      </div>
    </footer>
  )
}

export default Footer
